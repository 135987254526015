import './App.css';

function App() {
  
  return (
    
    <body>

    <div className='w-full flex justify-center items-center'>
    <div className='w-[90%] max-w-lg h-10 flex items-center justify-center bg-white border-4 rounded-md mt-4'>
      <p className='text-2xl font-serif text-black cursor-default'>Iskaffe</p><p className='text-red-600 cursor-default'>.se</p>
    </div>
    </div>

    <div>
      
    </div>

    </body>
  );
}

export default App;
